// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-narative-gatsby-theme-novela-templates-article-template-tsx": () => import("./../../src/@narative/gatsby-theme-novela/templates/article.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-article-template-tsx" */),
  "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx": () => import("./../../src/@narative/gatsby-theme-novela/templates/articles.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-introduction-js": () => import("./../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */)
}

